<template>
	<div>
		<v-item-group
			v-model="selected"
			:multiple="multiple"
			:disabled="isDisabled"
		>
			<div
				class="mw-option-tile-wrapper"
				v-for="option in options"
				:key="option"
			>
				<v-item v-slot="{active, toggle}" :value="answerOptions[option].value">
					<v-card
						:class="active ? 'active' : ''"
						class="mw-option-tile"
						transparent
						tabindex="0"
						@keydown.space="(e) => selectOption(e, toggle)"
						@click="(e) => selectOption(e, toggle)"
					>
						{{ answerOptions[option].text }}
					</v-card>
				</v-item>
				<slot
					name="pollResults"
					v-bind:answer="answerOptions[option].value"
				></slot>
			</div>
		</v-item-group>
	</div>
</template>

<style lang="less">
	@import "../../../../src/assets/css/survey/answerOptions.less";
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	export default {
		name: "RadioTiles",
		props: {
			id: {},
			value: {},
			disabled: {},
			multiple: {type: Boolean, default: false}
		},
		data: () => {
			return {
				selected: false
			};
		},
		computed: {
			...Vuex.mapState({
				answerOptions(state) {
					return state.questionTemplates.data[this.id].answer_options;
				}
			}),
			questionTemplate() {
				return this.$store.state.questionTemplates.data[this.id];
			},
			isDisabled() {
				return this.disabled || this.questionTemplate.disable_input;
			},
			options() {
				if (!this.answerOptions) {
					return [];
				}
				return this.mwutils.getPositions(this.answerOptions);
			}
		},
		watch: {
			selected() {
				this.$emit("update", this.selected);
			},
			value: {
				immediate: true,
				handler(data) {
					this.selected = data;
				}
			}
		},
		methods: {
			warn() {
				this.$emit("error");
			},
			selectOption(e,cb){
				e.preventDefault();
				this.isDisabled ? this.$emit("warn") : cb()
			},
		}	
	};
	//
</script>
"
